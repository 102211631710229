import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { CookieService } from 'ngx-cookie-service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root',
})
export class PortalBxdDownloadService {
  // currentValue = this.valueSource.asObservable();
  constructor(
    private windowRef: WindowRef,
    private cookieService: CookieService,
    private customHttp: BoxoutEndpointService
  ) {}


  showSpinner = new BehaviorSubject<boolean>(false);

  downloadReport(
    pageName: string,
    userName: string,
    event: any,
    downloadPayload: any,
    type: string,
  ) {
    this.showSpinner.next(true);
    let downloadUrl = `users/${userName}/reports/downloadExcel/${pageName}`;
    let selectedEntries = false;
    let currentBtn = event.currentTarget;
    currentBtn.disabled = true;
    selectedEntries = type === 'exportSelected' ? true : false;

    let fileName:string;
    if(pageName === 'productList') {
      fileName = 'productlist';
    } else if(pageName === 'quarantine') {
      fileName = 'quarantineInventory';
    } else {
      fileName = pageName;
    }

    this.customHttp
      .postCSV(downloadUrl, {
        selectedEntries: selectedEntries,
        productCodes: selectedEntries ? downloadPayload : [],
      })
      .subscribe(
        (data: any) => {
          var blob = new Blob([data], {
            type: 'application/octet-stream;charset=utf-8',
          });
          saveAs(blob, `${fileName}.xlsx`);
          currentBtn.disabled = false;
          this.showSpinner.next(false);
        },
        (error: any) => {
          currentBtn.disabled = false;
          this.showSpinner.next(false);
        }
      );
  }
}
