import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { SiteContextConfig } from "@spartacus/core";
import { PageLoaderService } from "./page-loader.service";
import { PortalBxdDownloadService } from "../../components/portal-boxout/portal-bxd-download.service";

@Component({
  selector: "app-page-loader",
  templateUrl: "./page-loader.component.html",
})
export class PageLoaderComponent implements OnInit, OnDestroy {
  visible = false;
  showLoader=false;
  showLoading=false;

  baseSiteName: any;
  baseSiteNameVal: string;
  showSpinnerIcon: boolean = false;
  spunnerSubscription: any;
 
  constructor(private config: SiteContextConfig,public pageLoaderService: PageLoaderService, private bxdDownLoadService: PortalBxdDownloadService, private cdr: ChangeDetectorRef ) {}

  ngOnInit() {

    // this.pageLoaderService.loader.subscribe((res)=>{
    //   this.showLoading=res;
    // })
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
    }


    this.spunnerSubscription = this.bxdDownLoadService.showSpinner.subscribe((res)=>{
      this.showSpinnerIcon=res;
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy() {
    if(this.spunnerSubscription){
      this.spunnerSubscription.unsubscribe();
    }
  }

}
