import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, OccConfig, provideConfig } from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { SmartEditConfig } from '@spartacus/smartedit/root';
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from '@spartacus/storefront';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    /** This is the configuration used when we have same API URL's for all 8 sites  */ 
    provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.occBaseUrl,
          prefix: environment.occApiPrefix
        }
      },
    }), 
    
    /** This is the configuration used when we have different API URL's for all 8 sites  */ 
   // provideConfigFactory(getBaseUrl , [DOCUMENT]),
    
    provideConfig(<SmartEditConfig>{
      smartEdit: {
        storefrontPreviewRoute: environment.smartEditPreviewUrl,
        allowOrigin: environment.smartEditAllowOrigin,
      }
    }), provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }), provideConfig(<FeaturesConfig>{
      features: {
        level: '2211.28'
      }
    }), provideConfig(defaultB2bOccConfig), provideConfig(<OccConfig>{ // defaultB2bCheckoutConfig removed as its removed in spartacus 6
    backend: {
      occ: {
        baseUrl: environment.occBaseUrl,
        prefix: environment.occApiPrefix
      }
    },
	 })
  ]
})
export class SpartacusConfigurationModule { }

function getBaseUrl(doc: Document) {
  const urlhost: any = doc.location.host;
  let domain:any;
  switch(urlhost) {
    case(urlhost.includes('dv')): {
      domain = 'dv';
      break;
    }
    case(urlhost.includes('qa')): {
      domain = 'qa';
      break;
    }
    case(urlhost.includes('prod')): {
      domain = 'prod';
      break;
    }
    default: {
      domain = 'localhost';
      break;
    }
  }
  let site = '';
  switch(urlhost) {
    case(urlhost.includes('boxout')): {
      site = 'boxout';
      break;
    }
    case(urlhost.includes('meyerspa')): {
      site = 'meyerspa';
      break;
    }
    case(urlhost.includes('meyerpt')): {
      site = 'meyerpt';
      break;
    }
    case(urlhost.includes('meyerdc')): {
      site = 'meyerdc';
      break;
    }
    case(urlhost.includes('elivatefitness')): {
      site = 'elivatefitness';
      break;
    }
    case(urlhost.includes('millikenmedical')): {
      site = 'millikenmedical';
      break;
    }
    case(urlhost.includes('obagiorder')): {
      site = 'obagiorder';
      break;
    }
    case(urlhost.includes('muradpro')): {
      site = 'muradpro';
      break;
    }
    default: {
      site = 'boxout';
    }
  }
  let url = '';
  if(domain !== 'localhost') {
    url = 'https://api.'+domain+'.'+site+'.com';
  } else {
    url = 'http://localhost:9002'; // This can be changed to the speciic domain url if we need to point our local to d1, s1 
    // url = "https://api.qa'+site+'.com"; // Example how to point to a particular environment on local
  }
  return {
    backend: {
      occ: {
        baseUrl: url,
        prefix: environment.occApiPrefix
      }
    }
  }
}
