import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageLoaderComponent } from 'src/app/feature/pages/page-loader/page-loader.component';



@NgModule({
  declarations: [PageLoaderComponent],
  imports: [
    CommonModule,
  ],
  exports: [PageLoaderComponent]
})
export class LoaderModule { }
