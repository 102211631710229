import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { ShipToBusinessAccountModalComponent } from 'src/app/shared/components/cartShipto-business/ship-to-business-account-modal/ship-to-business-account-modal.component';
import { ReusableAddressFormComponent } from 'src/app/shared/components/reusable-address-form/reusable-address-form.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { CustomValidationService } from 'src/app/shared/services/common/custom-validation.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-create-business-acct',
  templateUrl: './create-business-acct.component.html'
})
export class CreateBusinessAcctComponent implements OnInit,OnDestroy {
  emailRegex = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,15}';
  @ViewChild(ReusableAddressFormComponent, { static: true })
  public addressFormComponent!: ReusableAddressFormComponent;
  shipToCartComponentName:any='';
  public currentTheme: any;
  user: User;
  userId:any;
  userSub: Subscription;
  createBusinessAccountForm: any;
  dataFromRecommendedDialogEditButton:any;
  siteData:any;
  smartyKeyValForAddressValidation:any;

  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  shipToAccountId: any;
  smartyValidationLicense: any;
  smartyAutoCompleteLicense: any;



constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateBusinessAcctComponent>,
    public dialog: MatDialog,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private theme: CurrentThemeService, 
    private headerService:HeaderService,
    private customValidator: CustomValidationService,
    private customHttp: BoxoutEndpointService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>,
    private inputValidationService:InputValidationService
    ) {

    }

  ngOnInit(): void {
    if(this.data?.componentName=='shipTocartBusinessAccount'){
      this.shipToCartComponentName=this.data.componentName;
    }
    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user?.user!;
      this.userId = this.user.uid;
     });

      this.createBusinessAccountForm = this.formBuilder.group({
        firstName: [(this.data?.value?.firstName ? this.data?.value?.firstName: ""), [Validators.required,Validators.maxLength(20)]],
        lastName: [(this.data?.value?.lastName ? this.data?.value?.lastName: ""),[Validators.required,Validators.maxLength(20)]],
        businessName: [(this.data?.value?.businessName ? this.data?.value?.businessName: ""),[Validators.required,Validators.maxLength(50)]],
        attn: [(this.data?.value?.attn ? this.data?.value?.attn: ""),[Validators.maxLength(30)]],
        address: this.addressFormComponent.createAddressForm(this.data?.value?.address),
        phoneNumber: [(this.data?.value?.phoneNumber ? this.data?.value?.phoneNumber: ""), [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
        mobileNumber: [(this.data?.value?.mobileNumber ? this.data?.value?.mobileNumber: ""),[Validators.minLength(10),Validators.maxLength(10)]],
        deliverNote: [(this.data?.value?.deliverNote ? this.data?.value?.deliverNote: ""),[Validators.maxLength(60)]],
        addressType:[(this.data?.value?.addressType ? this.data?.value?.addressType: "")],
        email: [this.data?.value?.email ? this.data?.value?.email : '', [Validators.required, Validators.pattern(this.emailRegex)],],
        confirmEmail: [this.data?.value?.confirmEmail ? this.data?.value?.confirmEmail : '', [Validators.required, Validators.pattern(this.emailRegex)],],
      },{
        validator: 
          this.customValidator.emailMatchValidator(
            'email',
            'confirmEmail',
          )
      }
      );


   
    
      //getting the key for smarty address API validation
      this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
        if(resp){
          this.siteData = resp;
          const smartyKeyVal: any = 'spartacus.config.smarty.key';
          const smartyValidationLicenseKey: any = 'spartacus.config.smarty.validation.license';
          const smartyAutoCompleteLicenseKey: any = 'spartacus.config.smarty.autocomplete.license';
          this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
          this.smartyValidationLicense = this.siteData[smartyValidationLicenseKey];
          this.smartyAutoCompleteLicense = this.siteData[smartyAutoCompleteLicenseKey];
        }
        
      });
     
    
    

     /** Taking themes from themeservice **/
     this.currentTheme = this.theme?.getCurrentTheme();


       //when user clicks Edit This Address button on recommened address dialog then 
    //below code populates Create business account form again with prefilled values

     this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData)=>{
      
        this.dataFromRecommendedDialogEditButton=sentData;

        if(this.dataFromRecommendedDialogEditButton.editButtonClicked
          && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateBusinessAccount"){
            let editAddData=this.dataFromRecommendedDialogEditButton.editAddressData;
            editAddData.addressType=editAddData.addressType;
            this.createBusinessAccountForm.patchValue(editAddData);
           
      };
      
      if(!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateBusinessAccountReopened"))))
      && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateBusinessAccount"){
      
        this.windowRef.sessionStorage?.setItem("CreateBusinessAccountReopened","true");

        if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateBusinessAccountReopened"))))){

          this.dialog.open(CreateBusinessAcctComponent,
            {
              panelClass: [this.currentTheme, 'business-account-dialogs-style'],
              autoFocus: false,
              disableClose: true,
              data:this.createBusinessAccountForm
            },
            );


            if(Object.keys(this.dialogRef).length > 0 ){
              this.dialogRef.close();
            } 
        }
        
        }
    });

   
    

    //to get userid 
    // this.headerService.isLoggedIn.subscribe(response => {
    //   this.userData = response;
    // });
    // this.userData?.subscribe((data: any) => {
    //   this.userId = data?.uid;
    // });
    
    //scroll theme for create business dialog
    this.themesForScroll[0].className += " custom-modal";

    this.smartyAddressValidationService.savedFormDataSource.subscribe((data)=>{

      if(data && data.componentRef === "CreateBusinessAccount" &&
       !(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalBusinessFormSubmitCalled"))))){
        this.finalFormSubmit(data);
      }
  },(error)=>{
  })

  }

 

// This method is called on click Create Account Button 
  createBusinessAccount() {
    

    if(this.createBusinessAccountForm.valid){
      let addressOne= this.createBusinessAccountForm.get('address')?.value.addressOne;
      let optionalAddressTwo= this.createBusinessAccountForm.get('address')?.value.optionalAddressTwo;
      let cityName= this.createBusinessAccountForm.get('address')?.value.cityName;
      let stateIsocode= this.createBusinessAccountForm.get('address')?.value.stateName;
      let stateText=this.smartyAddressValidationService.getStateText(stateIsocode);

      let zipCode= this.createBusinessAccountForm.get('address')?.value.zipCode;
      let countryText='United States';

      let componentReference="CreateBusinessAccount";

      //close the create businness account form when form is valid and its going for smarty address 
      //validation which either opens recommended address dialog or submits the create businness account form
      this.dialogRef.close();
            //This is used to validate address in Create account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      this.smartyAddressValidationService.addressValidation(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,this.createBusinessAccountForm,this.smartyKeyValForAddressValidation,componentReference, this.smartyValidationLicense, this.smartyAutoCompleteLicense);

    }else{
     
      //invalid form , make it valid 
      this.createBusinessAccountForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }


  //To submit the form and create business Account
  finalFormSubmit(formData:any){

    this.windowRef.sessionStorage?.setItem("finalBusinessFormSubmitCalled","true");

    const requestBody=this.formRequestBody(formData);

    let apiUrl=`users/${this.userId}/orgUnits?fields=DEFAULT`;

   if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalBusinessFormSubmitCalled"))))){
    this.customHttp.post(apiUrl, requestBody).subscribe(
      (data:any) => {

        this.windowRef.sessionStorage?.removeItem("finalBusinessFormSubmitCalled");
        //to close recommended address dialog when business account is created
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }

        if(this.shipToCartComponentName && this.shipToCartComponentName==='shipTocartBusinessAccount'){
           //To show success alert message when address is created for ship to businnessAccount
           let msg={val:true, msgData: formData};
           this.newAccountCreatedForCartShipToBusinessAcc(msg);       
  
         }

else{
        //to refresh the data on my business Accounts page 
        //To show success alert message on My business Accont page when business account is created
        this.smartyAddressValidationService.saveBusinessAccountAlert(true);
}
      },
      (error:any) => {

        //to close recommended address dialog when business account is not created
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }
       if(this.shipToCartComponentName && this.shipToCartComponentName==='shipTocartBusinessAccount'){
          //To show success alert message when address is created for ship to businnessAccount
          let msg={val:false, msgData: formData};
          this.newAccountCreatedForCartShipToBusinessAcc(msg);       
 
        }
        else{
        //To show success alert message on My business Accont page when business account is not created
        this.smartyAddressValidationService.saveBusinessAccountAlert(false);
        }
      });
   }

  }


  // This method is used to form request body which will be sent in create business account POST Call
 formRequestBody(body:any): any{

    if(body){
    return {
      active: true,
      addresses: [
        {
          addressType: body?.addressType,
          attendant: body?.attn,
          country: {
            isocode: body.address.countryName,
            name: "United States"
          },
          defaultAddress: true,
          deliveryNote: body.deliverNote,
          district: body.address.cityName,
          line1: body.address.addressOne,
          line2: body.address.optionalAddressTwo,
          postalCode: body.address.zipCode,
          region: {
            countryIso: body.address.countryName,
            isocode: body.address.stateName,
            isocodeShort: body.address.stateName,
            name: this.smartyAddressValidationService.getStateText(body.address.stateName)
          },
          town: body.address.cityName,
          visibleInAddressBook: true
        }
      ],
      firstName: body.firstName,
      lastName: body.lastName,
      mobile: body.mobileNumber,
      name: body.businessName,
      phone: body.phoneNumber,
      email: body.email,
      confirmEmail: body.confirmEmail,
    }
    
    }
    
    }

    ngOnDestroy(): void {
      if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalBusinessFormSubmitCalled"))))){
        this.windowRef.sessionStorage?.removeItem("finalBusinessFormSubmitCalled");
      }

      if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateBusinessAccountReopened"))))){
        this.windowRef.sessionStorage?.removeItem("CreateBusinessAccountReopened");
      }
      
      if(this.userSub) {
        this.userSub.unsubscribe();
      }
      
    }

    openShipToBusinessDrawer(){
      this.dialogRef.close();
      this.dialog.open(ShipToBusinessAccountModalComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['wishlist-drawer', this.currentTheme],
        
      });
    }


    newAccountCreatedForCartShipToBusinessAcc(val:any){
      this.dialog.open(ShipToBusinessAccountModalComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['wishlist-drawer', this.currentTheme],
        data: {
          value:{
            showSuccessForCart:val?.val,
            showFailureForCart:!(val?.val)
          }
        }
      });
    }

    checkSpecChar(event:any){
      this.inputValidationService.checkRegisterSpecChar(event);
    }
}
