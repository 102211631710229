import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { UtilService } from 'src/app/core/services/util.service';
import { RegisterService } from 'src/app/feature/components/register-account/register.service';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CartService } from '../../services/common/cart.service';
import { GTMService } from '../../services/common/gtm.service';
import { HeaderService } from '../../services/header.service';
import { ScriptService } from '../../services/script.service';
import { SwitchAccountDialogComponent } from '../switch-account-dialog/switch-account-dialog.component';

declare let grecaptcha: any;
@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html'
})
export class LoginDialogComponent implements OnInit {
  token: any;
  boolSignIn: boolean = true;
  boolPassword: boolean = false;
  boolForgotPassword: boolean = false;
  emailRegex = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,15}';
  validEmail:boolean =  true ;
  validPassword: boolean = true;
  theme: any = this.document.getElementsByTagName('app-root')[0].classList;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  widgetId: any;
  emptyUserId: boolean;
  emptyPassword: boolean;
  currentSite: any = this.headerService.getCurrentSite()[0];
  siteData: any;
  recaptchaKey: any;
  baseSiteName: any;
  baseSiteNameVal: any;
  backScreen: boolean = false;
  baseUrl: string;
  siteName: any;
  siteKey: string;
  recaptchaFlag: any;
  baseSiteNameForRegister:any;
  myBoxoutPortal: boolean = false;
  recaptchaToken: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    public dialog: MatDialog,
    protected loginService: LoginFormComponentService,
    private changeDetectorRef: ChangeDetectorRef,
    private customHttp: BoxoutEndpointService,
    private windowRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    private cartService:CartService,
    private router: Router,
    private routes: ActivatedRoute,
    private config: SiteContextConfig,
    private headerService: HeaderService,
    public gtmService: GTMService,
    private pageLoaderService:PageLoaderService,
    private registerService:RegisterService,
    private utilService: UtilService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private scriptService: ScriptService
  ) {
    if(data?.isThroughSalesRep) {
      this.boolForgotPassword = true;
      this.boolPassword = false;
      this.boolSignIn=false;
    }

    if(data?.componentName === 'frgtPwd') {
      setTimeout(() => {
        this.isForgotPassword();
      },10);
    }

    this.headerService.recaptchaEnableData.subscribe((resp:any)=>{
      if(resp){
        this.recaptchaFlag = resp;
      }
    })
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameForRegister=this.baseSiteName[0].toUpperCase();
    }
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
    } 
   }

  valueChangePassword(){
    this.validPassword= true;
  }

  valueChangeEmail(){
    this.validEmail= true;
  }

  //===============SignIn Form===============
  signIn: FormGroup = new FormGroup({
    userId: new FormControl(this.data?.userEmail?this.data?.userEmail:'', [
      Validators.required,
      Validators.pattern(this.emailRegex)
    ]),
    password: new FormControl('', Validators.required),
  });

 //===============userLogin Form===============
  userLogin: FormGroup = new FormGroup({
    userId: new FormControl(this.data?.userEmail?this.data?.userEmail:''),
    password: new FormControl(''),
  });

  //===============ForgotPassword Form===============
  forgotPassword: FormGroup = new FormGroup({
    userId: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailRegex)
    ])
  });

  //===============Validate UserID===============
  validateUserId(email: any): any{
    const req = { email };
    let apiURL = `validate`;
      return this.customHttp.post(apiURL, req);
  }

  //===============Validate Password===============
  validatePassword(email: any ,password: any ) {
    const req = { email, password , recaptchaToken: this.recaptchaToken, isRegister: false};
    let apiURL = `validateLogin`;
      return this.customHttp.post(apiURL, req);
  }

  //===============Validate Recaptcha===============
  validateRecaptcha(token: any){
    let apiURL = `recaptcha?fields=DEFAULT&recaptchaToken=${token}`;
    return this.customHttp.get(apiURL);
  }

  //===============Close dialog box on cross icon===============
  closeDialog(): void {
    this.dialogRef.close();
  }

  //===============Login functionality===============
  onSubmit(): void {
    this.windowRef.localStorage?.setItem('isLoggedIn' , 'true');
    this.windowRef.localStorage?.setItem('loggedInFrom', this.windowRef?.location?.pathname ? decodeURIComponent(this.windowRef.location.pathname) : '');
    this.loginService.form = this.userLogin;
    this.loginService.login();
    this.windowRef.sessionStorage?.setItem('loginSource', 'true');
    //this.gtmService.setLoginData(); change done to send the user obj in datalayer
    this.dialogRef.close();
    // save current route first
    const currentRoute = this.router.url.split('?');
    //Checking search route
    /** when we need basesite, language and locale in the site URL */
    //if(currentRoute[0] == `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/search`){

     /** when we dont need basesite, language and locale in the site URL - This will not work in local*/
    if(currentRoute[0] == `/search`){
      //re rendering current route for price call
      let text = this.routes.snapshot.queryParamMap.get('text');
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/search'],{
            queryParams:{
              text: text
            }
          });
      });
    }
     //Navigate to base page if logged in user is on registerpage
     /** when we need basesite, language and locale in the site URL */ 
     //else if(currentRoute[0] == `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/register`){

     /** when we dont need basesite, language and locale in the site URL - This will not work in local*/
    else if(currentRoute[0] == `/register`){
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/']);
        }); 
      }

    else if(this.data?.entryPoint === 'contentPage') {
      this.router.navigateByUrl('/').then(() => {
        this.router.navigate([this.data?.redirect]);
      });
    }

    else if(this.windowRef?.document?.getElementById('loginRedirect')) {
      this.router.navigateByUrl('/').then(() => {
        this.router.navigate([this.document?.getElementById('loginRedirect')?.getAttribute('data-loggedInUrl')]);
      });
    }
  }

  //===============Recaptcha Callback Function===============
  onSubmitRecaptcha = (token: any) => {
    // this.validateRecaptcha(token).subscribe(
    //   (resp: any) =>{
    //       this.boolPassword = true;
    //       this.boolSignIn = this.boolForgotPassword = false;
    //       this.validEmail = true;
    //       this.changeDetectorRef.detectChanges();
    //   }
    // );

    this.boolPassword = true;
    this.boolSignIn = this.boolForgotPassword = false;
    this.validEmail = true;
    this.recaptchaToken = token;
    this.changeDetectorRef.detectChanges();
  }

  //===============Opening Password dialog and Recaptcha functionality{UserId Page}===============
  onSignInClick(){
    this.validPassword = true;
    if(this.signIn.value.userId !== '' && this.signIn.value.userId !== null && !this.signIn.get('userId')?.hasError('pattern')){
      this.emptyUserId = false;
      this.validateUserId(this.signIn.value.userId).subscribe(
        (data: any) => {
            if(data.success == 'true'){
              //to check recaptcha issue in S1 environment
              // if(this.windowRef.location.port == '4200'){
              //   this.boolPassword = true;
              //   this.boolSignIn = this.boolForgotPassword = false;
              //   this.validEmail = true;
              // }
              //  else 
              //  {
                if(this.recaptchaFlag === "true") {
                  this.recaptchaFunction();
                } else {
                  this.boolPassword = true;
                  this.boolSignIn = this.boolForgotPassword = false;
                  this.validEmail = true;
                }
              //  }
            }
            else {
              this.validEmail = false;
              this.signIn.controls['userId'].markAsTouched();
              this.signIn.controls['userId'].setErrors({'invalid': true});
            }
        },
        (error: any) => {
          this.validEmail = false;
          this.signIn.controls['userId'].markAsTouched();
          this.signIn.controls['userId'].setErrors({'invalid': true});
        }
      );
    }
    else
    this.emptyUserId = true;
  }

  //=============== Recaptcha Function ===============
  recaptchaFunction(){
    if(this.signIn.value.userId !== '' && !this.signIn.get('userId')?.hasError('pattern')) {
      if(grecaptcha){
        grecaptcha?.execute(this.widgetId);
      }
      else{
          this.boolPassword = true;
          this.boolSignIn = this.boolForgotPassword = false;
          this.validEmail = true;
      }
    }
  }

  //===============Change UserID===============
  onChangeUserIdClick(){
    // closing the popup and opening again in order to reset the recaptcha
    this.dialogRef.close();
    const dialogRef = this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.theme, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true
      });
    
  }

  //===============Continue button Functionality {Password Page}===============
  onContinueclick(){
    let userId = this.signIn.value.userId;
    let password = this.signIn.value.password;
    if(this.signIn.value.password !== '' && this.signIn.value.password !== null) {
      this.emptyPassword = false;
      this.validatePassword(userId,password).subscribe((resp:any)=>{
		    this.userLogin.patchValue({
          userId: resp?.uid,
			    password: password
		    });
          if(resp?.success == 'true' && resp.b2bUnits && Object.keys(resp.b2bUnits).length !== 0){
            let navigateToUrl;
            if(this.data?.entryPoint === 'contentPage') {
              navigateToUrl = this.data?.redirect;
            }
            this.dialog.open(SwitchAccountDialogComponent,{
              data:{
                b2bUnits: resp,
                signInForm: this.userLogin,
                navigateToUrl
              },
              disableClose: true,
              panelClass: [this.theme ,'custom-dialog-size'],
              width:'730px',
            });
            this.dialogRef.close();
          }
          else if(resp.success == 'true'){
            //this.gtmService.setLoginData(); change done to send the user obj in datalayer
            this.onSubmit();
            this.dialogRef.close();
            this.windowRef.sessionStorage?.setItem('loginSource', 'true');
          }
          else if(resp.success == 'false'){
            this.validPassword = false;
            this.signIn.controls['password'].markAsTouched();
            this.signIn.controls['password'].setErrors({'invalid': true});
          }
        },
        (error: any) => {
          this.validPassword = false;
          this.signIn.controls['password'].markAsTouched();
          this.signIn.controls['password'].setErrors({'invalid': true});
        }
      );
    }
    else
    this.emptyPassword = true;
  }

  //===============Forgot Password opening===============
  isForgotPassword(){
    this.signIn.reset();
    this.validEmail = true;
    this.boolForgotPassword = true;
    this.boolPassword = this.boolSignIn = false;
  }

  //===============Send Link Functionality===============
  onSendLink(){
    if(this.forgotPassword.value.userId !== '' && this.forgotPassword.value.userId !== null){
      this.emptyUserId = false;
      this.validateUserId(this.forgotPassword.value.userId).subscribe(
        (data: any) => {
          if(data.success == 'true'){
          this.validEmail = true;
        }
        else {
          this.validEmail = false;
          this.forgotPassword.controls['userId'].markAsTouched();
          this.forgotPassword.controls['userId'].setErrors({'invalid': true});
        }
      },
        (error: any) => {
          this.validEmail = false;
          this.forgotPassword.controls['userId'].markAsTouched();
          this.forgotPassword.controls['userId'].setErrors({'invalid': true});
        }
      );
      if(this.validEmail) {
        let apiURL =`forgotPasswordEmail?fields=DEFAULT`;
        let req ={
          "email": this.forgotPassword.value.userId
        };
       
        this.customHttp.post(apiURL,req).subscribe((data:any)=>{
          if (data.isSuccess === true) {
            let dataObj;
            dataObj = {
              'event':'forgot-password',
              "email": this.forgotPassword.value.userId
            }
            this.boolForgotPassword = false;
            this.backScreen = true;
            this.gtmService.writeToDataLayer(dataObj);
          }         
        })

      }
    }
    else
    this.emptyUserId = true;
  }

  //Back tO screen functionality
  backToScreen(){
    this.boolForgotPassword = true;
    this.boolPassword = this.boolSignIn = this.backScreen = false;
  }

  //===============Login Button(ForgotPassword Page)===============
  onLogIn(){
    this.forgotPassword.reset();
    this.validEmail = true;
    this.boolSignIn = true;
    this.boolPassword = this.boolForgotPassword = false;
  }
  ngOnInit(): void {
    this.themesForScroll[0].className += " custom-modal";
    this.baseSiteName = this.config?.context?.baseSite;
    /** when we need basesite, language and locale in the site URL */
    //this.baseUrl = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}`;

    /** when we dont need basesite, language and locale in the site URL - this will not work in local */
    this.baseUrl = ``;
    
    //setting the recaptcha key
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
      if(isPlatformBrowser(this.platformId)){
        const currentSite = this.headerService.getCurrentSite();
        this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
          if(resp){
            this.siteData = resp;
            this.recaptchaKey = this.siteData['spartacus.config.gsecure.key.site.'+ currentSite];
          }
        });
      }
      //for base site name
      this.siteName = this.utilService.checkBaseSite();
    }

    // calling the recaptcha render
    if(grecaptcha && this.baseSiteNameVal !== 'bxdportal'){
      let that = this;
      grecaptcha.ready( function() {
        if(that.widgetId === -1 || that.widgetId === undefined){
          that.widgetId = grecaptcha?.render('g-recaptcha', {
            'sitekey' : that.recaptchaKey,
            'callback' : that.onSubmitRecaptcha,
            'size': 'invisible'
          }, 100);          
        }
      })
    }


    if (this.windowRef.location.hostname?.includes('portal.myboxout')) {
      this.myBoxoutPortal = true;
      this.changeDetectorRef.detectChanges();
    } else {
      this.myBoxoutPortal = false;
      this.changeDetectorRef.detectChanges();
    }
  }

}