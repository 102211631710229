import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { HeaderService } from '../../services/header.service';
import { ReusableAddressFormComponent } from '../reusable-address-form/reusable-address-form.component';

interface ClentType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-reusable-add-ship-to-address',
  templateUrl: './reusable-add-ship-to-address.component.html'
})
export class ReusableAddShipToAddressComponent implements OnInit, OnDestroy {

  @ViewChild(ReusableAddressFormComponent, { static: true })
  public addressFormComponent!: ReusableAddressFormComponent;


  smartyKeyValForAddressValidation:any;
  siteData:any;
  dataFromRecommendedDialogEditButton:any;
  public currentTheme: any;
  user: User;
  userId:any;
  siebelRowId:any;
  apiUrl:any;
  customerRole:any;
  userType:any;
  userSub: Subscription;
  addShipToAddressForm:any;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  smartyValidationLicense: any;
  smartyAutoCompleteLicense: any;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private smartyAddressValidationService: SmartyAddressValidationService,
    public dialogRef: MatDialogRef<ReusableAddShipToAddressComponent>,
    private headerService:HeaderService,
    private theme: CurrentThemeService,
    public dialog: MatDialog,
    private customHttp: BoxoutEndpointService,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>
    ) { }

  ngOnInit(): void {

   

    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId;
      this.customerRole = this.user?.customerRole;
      this.userType = this.user?.orgUnit?.unitType;
    });

     //to get userid 
    //  this.headerService.isLoggedIn.subscribe(response => {
    //   this.userData = response;
    // });
    // this.userData?.subscribe((data: any) => {
    //   this.userId = data?.uid;
    //   this.siebelRowId = data?.orgUnit?.siebelRowId;
    //   this.customerRole= data?.customerRole;
    //   this.userType=data?.orgUnit?.unitType;
    // });

    this.addShipToAddressForm = this.formBuilder.group({
      attn: [(this.data?.value?.attn ? this.data?.value?.attn: ""),[Validators.maxLength(30)]],
      address: this.addressFormComponent.createAddressForm(this.data?.value?.address),
      deliverNote: [(this.data?.value?.deliverNote ? this.data?.value?.deliverNote: ""),[Validators.maxLength(60)]],
      defaultShippgTo:[(this.data?.value?.defaultShippgTo ? this.data?.value?.defaultShippgTo: false)],
      defaultBillingTo:[(this.data?.value?.defaultBillingTo ? this.data?.value?.defaultBillingTo: false)],
      addressType:[(this.data?.value?.addressType ? this.data?.value?.addressType: "")],
      addAddressId:[(this.data?.value?.addAddressId ? this.data?.value?.addAddressId: this.siebelRowId)],
      componentName:[(this.data?.value?.componentName ? this.data?.value?.componentName: "")],
    });

    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();

    //scroll theme for reusable shpping to address dialog
    this.themesForScroll[0].className += " custom-modal";


    //getting the key for smarty address API validation
    this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        this.siteData = resp;
        const smartyKeyVal: any = 'spartacus.config.smarty.key';
        this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
        const smartyValidationLicenseKey: any = 'spartacus.config.smarty.validation.license';
        const smartyAutoCompleteLicenseKey: any = 'spartacus.config.smarty.autocomplete.license';
        this.smartyValidationLicense = this.siteData[smartyValidationLicenseKey];
        this.smartyAutoCompleteLicense = this.siteData[smartyAutoCompleteLicenseKey];
      }
      
    });

    //when user clicks Edit This Address button on recommened address dialog then 
    //below code populates Add Ship To form again with prefilled values

    this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData)=>{
      
      this.dataFromRecommendedDialogEditButton=sentData;

      if(this.dataFromRecommendedDialogEditButton.editButtonClicked
        && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateShipToAddressAccount"){
          let editAddData=this.dataFromRecommendedDialogEditButton.editAddressData;
          editAddData.addressType=editAddData.addressType;
          this.addShipToAddressForm.patchValue(editAddData);
         
    };
    
    if(!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateShipToAccountReopened"))))
    && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateShipToAddressAccount"){
    
      this.windowRef.sessionStorage?.setItem("CreateShipToAccountReopened","true");

      if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateShipToAccountReopened"))))){

          this.dialog.open(ReusableAddShipToAddressComponent,
            {
              panelClass: [this.currentTheme, 'business-account-dialogs-style'],
              autoFocus: false,
              disableClose: true,
              data:this.addShipToAddressForm
            },
            );
        }

          if(Object.keys(this.dialogRef).length > 0 ){
            this.dialogRef.close();
          } 
      
      }
  });

//On click of USe this address or Add Ship To  Account success scenerio

this.smartyAddressValidationService.savedFormDataSource.subscribe((data)=>{

  if(data && data.componentRef === "CreateShipToAddressAccount" && 
  !(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalAddShipToFormSubmitCalled"))))){
    this.finalFormSubmit(data);
  }
},(error)=>{
});

  }

  

  //This method is used to create Add Ship To Address on click on ADD Ship To button
  addShipToaddressAccount(){

    if(this.addShipToAddressForm.valid){
      let addressOne= this.addShipToAddressForm.get('address')?.value.addressOne;
      let optionalAddressTwo= this.addShipToAddressForm.get('address')?.value.optionalAddressTwo;
      let cityName= this.addShipToAddressForm.get('address')?.value.cityName;
      let stateIsocode= this.addShipToAddressForm.get('address')?.value.stateName;
      let stateText=this.smartyAddressValidationService.getStateText(stateIsocode);

      let zipCode= this.addShipToAddressForm.get('address')?.value.zipCode;
      let countryText='United States';

      //sending component reference so that when we subscribe subject releted to its specific component
      let componentReference="CreateShipToAddressAccount";

      //close the Add Ship To account form when form is valid and its going for smarty address 
      //validation which either opens recommended address dialog or submits the Add Ship To account form
      this.dialogRef.close();

      //This is used to validate address in Add Ship To account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      this.smartyAddressValidationService.addressValidation(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,this.addShipToAddressForm,this.smartyKeyValForAddressValidation,componentReference, this.smartyValidationLicense, this.smartyAutoCompleteLicense);
    }
    else{
      //invalid form alert
      this.addShipToAddressForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }


  //To submit the form and Add Ship To Account
  finalFormSubmit(formData:any){

    this.windowRef.sessionStorage?.setItem("finalAddShipToFormSubmitCalled","true");

    const requestBody=this.formRequestBody(formData);

    //api url for Add ship to account
    if(formData.componentName && (formData.componentName === 'myClientAddress' || formData.componentName === 'manageClientAddress')){
        
      this.apiUrl=`users/${this.userId}/clients/${formData.addAddressId}?fields=DEFAULT`;
    }
    else{
      this.apiUrl=`users/${this.userId}/orgUnits/${formData.addAddressId}/updateAddress?fields=DEFAULT`;
    }
     

   if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalAddShipToFormSubmitCalled"))))){
    this.customHttp.post(this.apiUrl, requestBody).subscribe(
      (data:any) => {

        this.windowRef.sessionStorage?.removeItem("finalAddShipToFormSubmitCalled");
        //to close recommended address dialog
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }
        //to refresh the data  page
        //To show success alert message when address is created
        
       if(formData.componentName && (formData.componentName === 'myClientAddress' || formData.componentName === 'manageClientAddress')){
        this.smartyAddressValidationService.saveClientAccountAlert(true);
       }
       else{
        this.smartyAddressValidationService.saveBusinessAccountAlert(true);
       }

      },
      (error:any) => {

        this.windowRef.sessionStorage?.removeItem("finalAddShipToFormSubmitCalled");
        //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);

       //   to close recommended address dialog
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }
        //To show error alert message when address is not created
       
      if(formData.componentName && (formData.componentName === 'myClientAddress' || formData.componentName === 'manageClientAddress')){
        this.smartyAddressValidationService.saveClientAccountAlert(false);
       }
       else{
        this.smartyAddressValidationService.saveBusinessAccountAlert(false);
       }

      });
   }

  }


  // This method is used to form request body which will be sent in Add Ship To account POST Call
 formRequestBody(body:any): any{

    if(body){
    return {
      addressType: body?.addressType,
      attendant: body?.attn,
      country: {
                 isocode: body.address.countryName,
                 name: "United States"
               },
      defaultAddress: true,
      deliveryNote: body.deliverNote,
      district: body.address.cityName,
      line1: body.address.addressOne,
      line2: body.address.optionalAddressTwo,
      postalCode: body.address.zipCode,
      region: {
              countryIso: body.address.countryName,
              isocode: body.address.stateName,
              isocodeShort: body.address.stateName,
              name: this.smartyAddressValidationService.getStateText(body.address.stateName)
               },
      town: body.address.cityName,
      visibleInAddressBook: true,
      shippingAddress:true,
      isPrimaryShippingAddress: body.defaultShippgTo,
      isPrimaryBillingAddress: body.defaultBillingTo
      }
    }
    
  }

  ngOnDestroy(): void {

    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalAddShipToFormSubmitCalled"))))){
      this.windowRef.sessionStorage?.removeItem("finalAddShipToFormSubmitCalled");
    }

    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateShipToAccountReopened"))))){
      this.windowRef.sessionStorage?.removeItem("CreateShipToAccountReopened");
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }



}
